body {
    padding-bottom: 50px;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
}

/* Navigation Bar */
.NavigationBar {
    background-color: #192024;
    color: #e64c40;
    padding: 0.7em 0em;
}

.HeaderBrand {
    font-weight: 600;
    font-size: 18px;
    padding-left: 1em;
}

.HeaderBrand:hover {
    color: #e2a62b;
    cursor: pointer;
    text-decoration: underline;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}

.SectionLinks {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0em 0.8em;
    font-size: 16px;
    font-weight: 600;
}

.SectionLinks:hover {
    color: #e2a62b;
}

/* Header */
.HeaderBackground {
    background-image: linear-gradient(to bottom, rgba(47, 61, 73, 0.5), rgba(47, 61, 73, 0.5)),
        url(./assets/images/network_background.png);
    background-size: cover;
    background-position: center;
    padding: 5em 0em;
}

.HeaderColumns {
    display: flex;
    align-items: center;
}

.AvatarContainer {
    display: flex;
    justify-content: flex-end;
}

.Avatar {
    width: 15em;
    height: 15em;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
}

.Greetings {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.15em;
    color: #e2a62b;
}

.HeaderEdu {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.15em;
    color: white;
}

/* Contact */
.ContactContainer {
    display: flex;
    justify-content: center;
    margin-top: -35px;
}

.button:hover {
    opacity: 0.95;
    box-shadow: 0px 0px 10px 5px grey;
}

.info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    margin: 0em 0.2em;
}

/*Resume*/
.ResumeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

.ResumeButton {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    background-color: #3b5998;
    border-radius: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.2em;
    width: 200px;
    height: 50px;
}

.ResumeButton:hover {
    color: white;
    opacity: 0.9;
}

/* Section Templates */
.SectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 2em;
    width: 80%;
}

.SectionTopPadding {
    height: 3em;
}

.SectionTitle {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    color: #e64c40;
    letter-spacing: 0.15em;
    text-decoration: underline;
}

.SectionContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Education */
.Education {
    margin: 0em 0.8em;
    padding: 0em 1em;
    border-bottom-width: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;
}

/* Tab Button */
.nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #ef7c00 !important;
    background-color: #777780 !important;
    border-radius: 50px !important;
    margin: 0em 0.5em !important;
    padding: 0.8em 1.2em !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.15em !important;
    text-align: center !important;
}

.nav-link:hover {
    opacity: 0.95 !important;
    background-color: #1d439c !important;
}

.nav-link.active {
    background-color: #002970 !important;
    box-shadow: 0px 0px 10px 5px grey !important;
}

.TabButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5em;
}

.TabDescription {
    margin-top: 1em;
    text-align: center;
}

.LanguageLogo {
    display: flex;
    justify-content: center;
}

.GridImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GridImageContainerBottom {
    justify-content: flex-end;
}

.row {
    padding: 1em 0em;
}

/* Timeline */
.TimelineContainer {
    padding-top: 1.5em;
}

/* Vertical Line */
.vertical-timeline::before {
    background: black;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px black, 0px 0px 10px 5px grey;
}

.vertical-timeline-element-content {
    box-shadow: 0px 0px 10px 5px grey;
}

/* List Items */
.ListContainer {
    width: 100%;
    padding: 0.8em 0em;
    border-bottom-width: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;
}

dt {
    padding: 0.1em 1em;
    font-size: 18px;
    font-weight: 700;
    color: black;
    width: 100%;
    overflow-wrap: break-word;
}

dd {
    padding: 0em 1em;
    font-size: 16px;
    color: black;
}

.TimelineTitle {
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
}

.badge {
    text-transform: uppercase;
}

.badge-dark {
    background-color: #192024;
}

.TechStack {
    color: #2ca8ff;
}

.Documentation {
    color: #e64c40;
}

.ListSubtitle {
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
}

/* Styles for medium screens */
@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .navbar-collapse {
        justify-content: center;
    }

    .SectionLinks {
        padding: 0.5em 0em;
        justify-content: center;
        font-weight: normal;
        text-transform: uppercase;
    }

    .AvatarContainer {
        justify-content: center;
    }

    .Greetings {
        padding-top: 1em;
        font-size: 25px;
        font-weight: 700;
        letter-spacing: 0.15em;
        color: #e2a62b;
        text-align: center;
    }

    .HeaderEdu {
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0.15em;
        color: white;
        text-align: center;
    }

    .SectionContainer {
        padding: 0px;
        width: 85%;
    }

    .SectionTopPadding {
        height: 5em;
    }

    .SectionTitle {
        font-size: 23px;
    }

    .nav-link {
        font-size: 13px !important;
        margin: 0.3em 0.2em !important;
        padding: 0.8em 0.3em !important;
        width: 30% !important;
        height: 80% !important;
    }

    dt,
    dd {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
}

/* Styles for small screens */
@media only screen and (max-width: 767px) {
    .navbar-collapse {
        justify-content: center;
    }

    .SectionLinks {
        padding: 0.5em 0em;
        justify-content: center;
        font-weight: normal;
        text-transform: uppercase;
    }

    .AvatarContainer {
        justify-content: center;
    }

    .Greetings {
        padding-top: 1em;
        font-size: 25px;
        font-weight: 700;
        letter-spacing: 0.15em;
        color: #e2a62b;
        text-align: center;
    }

    .HeaderEdu {
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0.15em;
        color: white;
        text-align: center;
    }

    .SectionContainer {
        padding: 0px;
        width: 85%;
    }

    .SectionTopPadding {
        height: 5em;
    }

    .SectionTitle {
        font-size: 23px;
    }

    .nav-link {
        font-size: 13px !important;
        margin: 0.3em 0.2em !important;
        padding: 0.8em 0.3em !important;
        width: 50% !important;
        height: 80% !important;
    }

    dt,
    dd {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
}

/* Animations */
.fadeIn {
    animation: fadeInAnimation ease 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fadeInFast {
    animation: fadeInAnimation ease 1s;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.bounce:hover {
    animation-name: bounce;
    animation-timing-function: ease;
}

@keyframes bounce {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(-5px);
    }
}

.rotate {
    animation: rotate90deg ease 0.5s;
    animation-iteration-count: 1;
}

@keyframes rotate90deg {
    0% {
        transform: rotate(-45deg);
        opacity: 0;
    }
    100% {
        transform: rotate(90deg);
        opacity: 1;
    }
}
